import '../styles/terms.css';

import { graphql } from 'gatsby';
import React from 'react';

// import PlanHeader from "../components/plan-header";
import Footer from '../components/footer';
import Menu from '../components/menu';
import SEO from '../components/seo';


export const pageQuery = graphql`
query {
  allStrapiArticle(filter:{title: {eq: "termsofservice"}}) {
    edges {
      node {
        body
        id
        title
      }
    }
  }
}
`;

function createMarkup( html ) {
  return { __html: html };
}

const TermsOfServicePage = ( { data } ) => {
  return (
    <>
      <SEO keywords="Bubble Insurance Terms of Service"
        title="Terms of Service and Insurance Related Notices"
        canonicalUrl='https://www.getmybubble.com/terms-of-service/'
        description="Know more about term of service and insurance related information and notices of Get My Bubble. If you have questions or feedback about the Site or these Site Terms, please contact us at (833) 900-2822"/>

      <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
        <Menu />

        <div className="company-info-container about-container" style={{ marginBottom:'48px' }}>
          <h1 className="carrier-plan-heading about-heading" >
              Terms of Service and Insurance Related Notices
          </h1>
        </div>

        <div className="container terms">
          {
            data.allStrapiArticle.edges.map( ( d, i ) => {
              return <div key={i} dangerouslySetInnerHTML={createMarkup( d.node.body )} />;
            } )
          }
        </div>

        <Footer />
      </div>
    </>

  );
};

export default TermsOfServicePage;
